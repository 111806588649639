import React, { FC } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import { Header } from './components/Header';
import { InfoBlocks } from './components/InfoBlocks';
import useScrollHighlight from './components/InfoBlocks/useScrollHighlight';
import { Main } from './components/Main';
import MetaTags from './components/MetaTags';
import { ScrollLockProvider, useScrollLock } from './contexts/ScrollLockContext';
import { ViewModeProvider } from './contexts/ViewModeContext';
import { GlobalStyle } from './globalsStyles';

const AppContent: FC = () => {
  const { highlightNews, highlightContacts, handleScrollToSection } = useScrollHighlight();
  const { isLocked } = useScrollLock();

  return (
    <>
      <MetaTags />
      <Header onScrollToSection={handleScrollToSection} />
      <Main />
      <InfoBlocks highlightNews={highlightNews} highlightContacts={highlightContacts} />
      <GlobalStyle isLocked={isLocked} />
    </>
  );
};

const App: FC = () => {
  return (
    <Router
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <ScrollLockProvider>
        <ViewModeProvider>
          <AppContent />
        </ViewModeProvider>
      </ScrollLockProvider>
    </Router>
  );
};

export default App;
