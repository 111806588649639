import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 44vw;
  min-height: 40vw;
  max-height: 712px;
  width: 100%;

  @media (orientation: portrait) and (max-width: 719px) {
    width: 100%;
    height: 84vw;
  }

  @media (max-width: 499px) {
    height: auto;
  }

  @media (min-width: 1040px) {
    flex-direction: row;
    max-width: 1920px;
  }
`;

export const MapWrapper = styled.div`
  height: 100%;
  flex-grow: 1;
  position: relative;
  display: flex;
  padding-right: 16px;

  @media (max-width: 1040px) {
    height: auto;
    padding-right: 0;
  }
`;
