import { pocketbaseUrl } from '../api';
import { IShopItem } from '../types';

export const extractUniqueCitiesFromArray = (locations: IShopItem[]): string[] => {
  const citiesSet = new Set<string>();

  locations.forEach((element) => {
    citiesSet.add(element.city);
  });

  return Array.from(citiesSet);
};

export const setImageSrc = (location: IShopItem) => {
  const result = [
    pocketbaseUrl, // api_url from flux
    'api',
    'files',
    location.collectionId,
    location.id,
    location.photo,
  ].join('/');

  return result;
};
