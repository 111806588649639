import React, { useState, useEffect, useRef, useCallback } from 'react';

import { Grid, AutoSizer } from 'react-virtualized';

import { IconArrowLeft, IconArrowRight } from '../../../assets/icons';
import { INewsParsedData, INewsItem } from '../../../types';

import {
  URLs,
  Pagination,
  GridSizes,
  SCROLL_THRESHOLD,
  SCROLL_CARDS,
  DEFAULT_RUBRIC,
} from './constants';
import {
  NewsContainer,
  NewsListItem,
  NewsContent,
  NewsHead,
  NewsTitle,
  NewsNav,
  NavButton,
  NavToggle,
  ToggleImage,
  ItemInner,
  ItemRubric,
  ItemHeading,
  ItemImage,
  ToggleLeft,
  ToggleRight,
  SkeletonWrapper,
  SkeletonImage,
  SkeletonText,
  SkeletonRubricShort,
  NewsListWrapper,
  GridCell,
} from './styled';

const decodeHtmlEntities = (text: string) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
};

interface NewsProps {
  highlightNews: boolean;
}

const fetchNewsData = async (page: number, perPage: number) => {
  try {
    const response = await fetch(`${URLs.WP_API_ENDPOINT}?_embed&per_page=${perPage}&page=${page}`);

    if (!response.ok) {
      throw new Error(`Fetch failed: ${response.status}`);
    }

    const data: INewsItem[] = await response.json();
    return data;
  } catch (error) {
    console.error('Fetch failed:', error);
    return [];
  }
};

export function News({ highlightNews }: NewsProps) {
  const gridRef = useRef<Grid | null>(null);
  const [newsData, setNewsData] = useState<INewsParsedData[]>([]);
  const [page, setPage] = useState(Pagination.INITIAL_PAGE);
  const [hasMore, setHasMore] = useState(true);
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const isLoadingMore = useRef(false);

  const fetchNews = useCallback(async (page: number, perPage: number) => {
    setLoading(true);
    const data = await fetchNewsData(page, perPage);

    const transformedData: INewsParsedData[] = data.map((item: INewsItem) => {
      const { link, title, yoast_head_json } = item;

      let imageUrl = '';

      if (yoast_head_json?.og_image?.[0]?.url) {
        imageUrl = yoast_head_json.og_image[0].url;
      }

      let rubric = DEFAULT_RUBRIC;

      if (yoast_head_json?.schema?.['@graph']) {
        const graphArray = yoast_head_json.schema['@graph'];

        for (const graphItem of graphArray) {
          if (graphItem.articleSection?.[0]) {
            rubric = graphItem.articleSection[0];
            break;
          }
        }
      }

      const decodedTitle = decodeHtmlEntities(title.rendered);

      return {
        url: link,
        rubric: rubric,
        title: decodedTitle,
        image: imageUrl,
        id: link,
      };
    });

    setNewsData((prevData) => {
      const existingDataMap = new Map<string, INewsParsedData>(
        prevData.map((item) => [item.url, item])
      );

      transformedData.forEach((item: INewsParsedData) => {
        existingDataMap.set(item.url, item);
      });

      return Array.from(existingDataMap.values());
    });

    if (transformedData.length < perPage) {
      setHasMore(false);
    }

    setLoading(false);
    isLoadingMore.current = false;
  }, []);

  useEffect(() => {
    if (!initialLoaded) {
      fetchNews(Pagination.INITIAL_PAGE, Pagination.PER_PAGE);
      setInitialLoaded(true);
    }
  }, [fetchNews, initialLoaded]);

  useEffect(() => {
    if (initialLoaded && page > Pagination.INITIAL_PAGE) {
      fetchNews(page, Pagination.PER_PAGE);
    }
  }, [fetchNews, page, initialLoaded]);

  const loadMoreItems = () => {
    if (hasMore && !isLoadingMore.current) {
      isLoadingMore.current = true;
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleGridScroll = ({
    scrollLeft,
    clientWidth,
    scrollWidth,
  }: {
    scrollLeft: number;
    clientWidth: number;
    scrollWidth: number;
  }) => {
    if (
      hasMore &&
      !isLoadingMore.current &&
      scrollLeft + clientWidth >= scrollWidth - SCROLL_THRESHOLD
    ) {
      loadMoreItems();
    }
  };

  const totalColumns =
    newsData.length > 0
      ? newsData.length + (loading ? Pagination.NUMBER_OF_SKELETONS : 0)
      : Pagination.NUMBER_OF_SKELETONS;

  const cellRenderer = ({
    columnIndex,
    key,
    style,
  }: {
    columnIndex: number;
    key: React.Key;
    style: React.CSSProperties;
  }) => {
    if (columnIndex >= newsData.length) {
      return (
        <GridCell key={key} style={style}>
          <SkeletonWrapper>
            <SkeletonImage />
            <SkeletonRubricShort />
            <SkeletonText />
          </SkeletonWrapper>
        </GridCell>
      );
    } else {
      const newsItem = newsData[columnIndex];
      return (
        <GridCell key={key} style={style}>
          <NewsListItem href={newsItem.url} target="_blank">
            <ItemImage src={newsItem.image} alt={newsItem.title} draggable="false" loading="lazy" />
            <ItemInner>
              <ItemRubric>{newsItem.rubric}</ItemRubric>
              <ItemHeading>{newsItem.title}</ItemHeading>
            </ItemInner>
          </NewsListItem>
        </GridCell>
      );
    }
  };

  const scrollByCards = (numCards: number) => {
    if (gridRef.current) {
      const scrollAmount = GridSizes.CELL_WIDTH * numCards;

      gridRef.current.scrollToPosition({
        scrollLeft: gridRef.current.state.scrollLeft + scrollAmount,
        scrollTop: 0,
      });
    }
  };

  const handleScrollLeft = () => {
    scrollByCards(-SCROLL_CARDS);
  };

  const handleScrollRight = () => {
    scrollByCards(SCROLL_CARDS);
  };

  return (
    <NewsContainer id="news">
      <NewsContent>
        <NewsHead>
          <NewsTitle $highlightNews={highlightNews}>Новости</NewsTitle>

          <NewsNav>
            <NavButton href={URLs.BET_NEWS_SITE} target="_blank">
              Все новости
            </NavButton>
            <NavToggle>
              <ToggleLeft onClick={handleScrollLeft}>
                <ToggleImage src={IconArrowLeft} draggable="false" loading="lazy" />
              </ToggleLeft>
              <ToggleRight onClick={handleScrollRight}>
                <ToggleImage src={IconArrowRight} draggable="false" loading="lazy" />
              </ToggleRight>
            </NavToggle>
          </NewsNav>
        </NewsHead>

        <NewsListWrapper>
          <AutoSizer>
            {({ width }) => (
              <Grid
                ref={gridRef}
                cellRenderer={cellRenderer}
                columnCount={totalColumns}
                columnWidth={GridSizes.CELL_WIDTH}
                height={GridSizes.GRID_HEIGHT}
                rowCount={1}
                rowHeight={GridSizes.GRID_HEIGHT}
                width={width}
                onScroll={handleGridScroll}
                scrollToAlignment="start"
              />
            )}
          </AutoSizer>
        </NewsListWrapper>
      </NewsContent>
    </NewsContainer>
  );
}
