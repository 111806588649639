import { useState, useEffect, useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { IShopItem } from '../../../types';
import { createSlug } from '../../../utils/createSlug';
import { extractUniqueCitiesFromArray } from '../../../utils/locations';

interface UseGetLocationsProps {
  allShops: IShopItem[];
}

export function useGetLocations({ allShops }: UseGetLocationsProps) {
  const [data, setData] = useState<IShopItem[]>([]);
  const [currentCity, setCurrentCity] = useState('');
  const [cities, setCities] = useState<string[]>([]);

  const locationRouter = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(locationRouter.search);
    const cityParam = params.get('city') || '';

    const uniqueCities = extractUniqueCitiesFromArray(allShops);
    setCities(uniqueCities);

    const matchingCity = uniqueCities.find((city) => createSlug(city) === createSlug(cityParam));

    if (matchingCity) {
      setCurrentCity(matchingCity);
    } else {
      setCurrentCity('');
    }
  }, [allShops, locationRouter.search]);

  useEffect(() => {
    if (currentCity) {
      const filteredData = allShops.filter(
        (shop) => createSlug(shop.city) === createSlug(currentCity)
      );
      setData(filteredData);
    } else {
      setData(allShops);
    }
  }, [currentCity, allShops]);

  const handleCityChange = useCallback(
    (event: React.FormEvent<HTMLSelectElement>) => {
      const city = event.currentTarget.value.trim();

      if (city === currentCity) {
        return;
      }

      setCurrentCity(city);

      const params = new URLSearchParams(locationRouter.search);
      if (city) {
        const citySlug = createSlug(city);
        params.set('city', citySlug);
      } else {
        params.delete('city');
      }

      params.delete('lat');
      params.delete('lon');

      const newPath = params.toString() ? `/?${params.toString()}` : '/';

      navigate(newPath, { replace: true });
    },
    [navigate, locationRouter.search, currentCity]
  );

  return {
    data,
    handleCityChange,
    currentCity,
    cities,
  };
}
