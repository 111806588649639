import React, { StrictMode } from 'react';

import ReactDOM from 'react-dom/client';

import App from './App';
import { GlobalStyle } from './globalsStyles';

// TODO (T.V.): Temporary switched off
// import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <GlobalStyle isLocked={false} />
    <App />
  </StrictMode>
);
