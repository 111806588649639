import styled from 'styled-components';

import {
  skeletonAnimation,
  InactiveMessageParagraph,
  activeShopBorder,
} from '../../../../globalsStyles';

import { IMessageActive, IListItemProps } from './types';

type CombinedProps = IMessageActive & IListItemProps;

export const ItemImage = styled.img<{ $isImageLoaded: boolean }>`
  display: ${({ $isImageLoaded }) => ($isImageLoaded ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  user-select: none;
  -webkit-user-select: none;
  opacity: 0.8;
  transition: opacity 0.3s ease;
`;

export const ListItem = styled.div<CombinedProps>`
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  margin-bottom: 12px;
  background-color: var(--bg-main);
  width: 100%;
  height: 14vw;
  max-height: ${({ $isMessageActive }) => ($isMessageActive ? '290px' : '210px')};
  min-height: ${({ $isMessageActive }) => ($isMessageActive ? '260px' : 'unset')};
  cursor: pointer;
  overflow: hidden;
  flex-direction: row;
  position: relative;
  transition:
    background-color 0.3s ease,
    cursor 0.3s ease;

  ${({ $isActive }) => ($isActive ? activeShopBorder : 'none')};

  &:hover {
    background-color: ${({ $isMessageActive }) =>
      $isMessageActive ? 'var(--bg-main)' : 'var(--control-secondary-hover)'};

    ${ItemImage} {
      opacity: 1;
    }
  }

  @media (max-width: 1400px) {
    height: 20vw;
  }

  @media (max-width: 1039px) {
    flex-direction: row;
    height: 17vw;
    max-height: ${({ $isMessageActive }) => ($isMessageActive ? '230px' : '180px')};
    min-height: ${({ $isMessageActive }) => ($isMessageActive ? '180px' : 'unset')};
    overflow: visible;
  }

  @media (max-width: 719px) {
    flex-direction: column;
    height: unset;
    max-height: ${({ $isMessageActive }) => ($isMessageActive ? '501px' : '451px')};
    width: calc(50% - 4px);
    overflow: visible;
    margin-bottom: 0;
  }

  @media (max-width: 499px) {
    width: 100%;
    max-height: ${({ $isMessageActive }) => ($isMessageActive ? '592px' : '542px')};
  }

  @media (max-width: 319px) {
    width: 100%;
    max-height: ${({ $isMessageActive }) => ($isMessageActive ? '465px' : '415px')};
  }
`;

export const ItemImageContainer = styled.div`
  width: 48%;
  height: 100%;
  aspect-ratio: 7.15 / 9;
  position: relative;

  @media (max-width: 1039px) {
    max-width: 220px;
    max-height: 180px;
  }

  @media (max-width: 719px) {
    width: 100%;
    height: 40vw;
    max-height: 286px;
    max-width: none;
  }

  @media (max-width: 499px) {
    width: 100%;
    height: 78vw;
    max-height: 395px;
    max-width: none;
  }

  @media (max-width: 499px) {
    width: 100%;
    max-height: 249px;
    max-width: none;
  }
`;

export const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  width: 52%;
  padding: 8px;
  user-select: none;
  -webkit-user-select: none;

  @media (max-width: 1039px) {
    width: calc(100% - 220px);
    padding: 8px 0;
  }

  @media (max-width: 719px) {
    width: 100%;
  }
`;

export const CommonDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  gap: 0.4vw;
  user-select: none;
  -webkit-user-select: none;
`;

export const CityName = styled.h3`
  font-size: 14px;
  font-weight: 400;
  color: var(--text-subtitle);
`;

export const PpsName = styled.h4`
  font-size: 16px;
  color: var(--text-title);
`;

export const WorkingHours = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: var(--text-title);
`;

export const BreakTimes = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: var(--text-title);
  letter-spacing: -0.3px;
`;

export const WorkingHoursLabel = styled.div`
  font-size: 12px;
  color: var(--text-subtitle);
`;

export const BreakTimesLabel = styled.div`
  font-size: 12px;
  color: var(--text-subtitle);
`;

export const ItemPointer = styled.img`
  width: 10px;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
`;

export const HeadingCity = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4px;
`;

export const ItemDetailsHeading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 4px;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: -6px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--text-title);
    opacity: 0.1;
  }

  @media (max-width: 1039px) {
    padding: 0 8px;
  }
`;

export const WorkingHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 4px;
  margin-top: 4px;
  opacity: 1;

  @media (max-width: 1039px) {
    padding: 0 8px;
  }
`;

export const BreakTimesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 4px;
  opacity: 1;

  @media (max-width: 1039px) {
    padding: 0 8px;
  }
`;

export const InactiveMessage = styled.p`
  ${InactiveMessageParagraph};
`;

export const SkeletonWrapper = styled(ListItem)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  padding: 20px;
`;

export const SkeletonImageContainer = styled.div`
  ${skeletonAnimation}
  position: relative;
  width: 48%;
  height: 100%;
  aspect-ratio: 7.15 / 9;
  overflow: hidden;
  border-radius: 8px;
`;

export const SkeletonImage = styled.div`
  ${skeletonAnimation}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;
