import styled from 'styled-components';

import { skeletonAnimation } from '../../../globalsStyles';

interface NewsTitleProps {
  $highlightNews: boolean;
}

export const NewsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
  width: 75%;
  height: 100%;

  @media (max-width: 1039px) {
    flex-direction: column;
    width: 100%;
    max-width: 990px;
    padding: 60px 0 60px 24px;
    margin: 0;
  }
`;

export const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: start;
  width: 100%;
  min-height: 309px;
`;

export const NewsHead = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;
`;

export const NewsTitle = styled.h2<NewsTitleProps>`
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0.37px;
  color: var(--text-title);
  text-shadow: ${({ $highlightNews }) =>
    $highlightNews ? `0 0 10px var(--highlight-headers)` : 'none'};
  transition: text-shadow 0.6s ease-in-out;
`;

export const NewsNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.87vw;
  min-height: 36px;
  gap: 8px;
`;

export const NavButton = styled.a`
  min-width: 102px;
  padding: 6px 10px;
  height: 36px;
  color: var(--text-body);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-list-cell);
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: var(--control-secondary-hover);
    color: var(--text-title);
  }
`;

export const NavToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 100%;

  @media (max-width: 499px) {
    display: none;
  }
`;

export const ToggleLeft = styled.div`
  width: 36px;
  height: 36px;
  background-color: var(--control-secondary);
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--control-secondary-hover);
  }
`;

export const ToggleRight = styled.div`
  width: 36px;
  height: 36px;
  background-color: var(--control-secondary);
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--control-secondary-hover);
  }
`;

export const ToggleImage = styled.img`
  width: 100%;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
`;

export const NewsListWrapper = styled.div`
  width: 100%;
  height: 244px;
`;

export const NewsListItem = styled.a`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 8px;
  height: 244px;
  width: 300px;
  background-color: var(--bg-list-cell);
  border-radius: 8px;
  gap: 6px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--control-secondary-hover);
  }
`;

export const ItemInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ItemRubric = styled.span`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.4px;
  color: var(--text-subtitle);
`;

export const ItemHeading = styled.h3`
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: 700;
  color: var(--text-title);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-height: 1.2;
  max-height: 4.5em;
`;

export const ItemImage = styled.img`
  width: 100%;
  max-width: 284px;
  height: 138px;
  border-radius: 4px;
  user-select: none;
  -webkit-user-select: none;
  object-fit: cover;
  overflow: hidden;
`;

export const GridCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 8px;
  height: 244px;
  width: 300px;
  background-color: var(--bg-list-cell);
  border-radius: 8px;
  gap: 6px;
`;

export const SkeletonImage = styled.div`
  ${skeletonAnimation}
  width: 100%;
  max-width: 284px;
  height: 138px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 4px;
  user-select: none;
  -webkit-user-select: none;
`;

export const SkeletonText = styled.div`
  ${skeletonAnimation}
  width: 100%;
  height: 20px;
  border-radius: 4px;
`;

export const SkeletonRubricShort = styled.div`
  ${skeletonAnimation}
  width: 60%;
  height: 20px;
  border-radius: 4px;
`;
