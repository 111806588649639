import React from 'react';

import { Helmet } from 'react-helmet';

const MetaTags: React.FC = () => (
  <Helmet>
    <title>Betera – Пункты Приема Ставок в Беларуси</title>
    <meta
      name="description"
      content="Betera – букмекерская контора в Беларуси. 11 пунктов приема ставок по всей стране, включая областные центры. Делайте ставки на спорт и киберспорт с Betera и наслаждайтесь игрой!"
    />
  </Helmet>
);

export default MetaTags;
