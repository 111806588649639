import React, { useState, useEffect, useRef, forwardRef } from 'react';

import { PointerSidebarIcon } from '../../../../assets/icons';
import { setImageSrc } from '../../../../utils/locations';

import {
  ListItem,
  ItemDetails,
  ItemImageContainer,
  ItemImage,
  ItemDetailsHeading,
  HeadingCity,
  ItemPointer,
  CityName,
  PpsName,
  WorkingHoursContainer,
  WorkingHoursLabel,
  WorkingHours,
  BreakTimesContainer,
  BreakTimesLabel,
  BreakTimes,
  InactiveMessage,
  SkeletonImage,
  CommonDetails,
} from './styled';
import { IShopItemProps } from './types';

export const ShopItem = forwardRef<HTMLDivElement, IShopItemProps>(
  ({ shopInfo, onShopItemClick, isMessageActive, activeMessage, isActive }, ref) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);

    const handleClick = () => {
      const { latitude, longitude } = shopInfo;

      const isCanClickByItem = latitude !== undefined && longitude !== undefined;

      if (isCanClickByItem) {
        onShopItemClick(latitude, longitude, shopInfo);
      }
    };

    const handleImageLoad = () => {
      setIsImageLoaded(true);
    };

    const handleImageError = () => {
      setIsImageLoaded(true);
    };

    useEffect(() => {
      const img = imgRef.current;
      if (img && img.complete) {
        setIsImageLoaded(true);
      }
    }, []);

    return (
      <ListItem
        ref={ref}
        onClick={handleClick}
        $isMessageActive={isMessageActive}
        $isActive={isActive}
      >
        <ItemImageContainer>
          {!isImageLoaded && <SkeletonImage aria-hidden="true" />}
          <ItemImage
            ref={imgRef}
            src={setImageSrc(shopInfo)}
            draggable="false"
            onLoad={handleImageLoad}
            onError={handleImageError}
            $isImageLoaded={isImageLoaded}
          />
        </ItemImageContainer>

        <ItemDetails>
          <CommonDetails>
            <ItemDetailsHeading>
              <HeadingCity>
                <ItemPointer src={PointerSidebarIcon} alt="" />
                <CityName>{shopInfo.city}</CityName>
              </HeadingCity>
              <PpsName>{shopInfo.address}</PpsName>
            </ItemDetailsHeading>
            <WorkingHoursContainer>
              <WorkingHoursLabel>Режим работы:</WorkingHoursLabel>
              <WorkingHours>{shopInfo.workingTime}</WorkingHours>
            </WorkingHoursContainer>
            <BreakTimesContainer>
              <BreakTimesLabel>Технические перерывы:</BreakTimesLabel>
              <BreakTimes>{shopInfo.technicalPauses}</BreakTimes>
            </BreakTimesContainer>
          </CommonDetails>

          {isMessageActive && activeMessage && <InactiveMessage>{activeMessage}</InactiveMessage>}
        </ItemDetails>
      </ListItem>
    );
  }
);

ShopItem.displayName = 'ShopItem';
